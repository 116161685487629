import { AdTemplate } from '@/components/AdTemplate';
import { Base } from '@/components/Base';
import { Consent } from '@/components/Consent';
import { PageMeta } from '@/components/PageMeta';
import { Pagination } from '@/components/Pagination';
import { SchemaMarkup } from '@/components/SchemaMarkup';
import { Tracking } from '@/components/Tracking';
import { Meta } from '@/types/content';
import { isDeviceInvalid } from '@/utils/isDeviceInvalid';
import { slugify } from '@/utils/slugify';
import { CLOUDFLARE_TTL } from '@/utils/ttl';
import { withCacheControlHeaders } from '@/utils/withCacheControlHeaders';
import { withLockedContentHeaders } from '@/utils/withLockedContentHeaders';
import { withOcelotCacheTagsHeaders } from '@/utils/withOcelotCacheTagsHeaders';
import { withSerializationGuard } from '@/utils/withSerializationGuard';
import { withTestContentHeaders } from '@/utils/withTestContentHeaders';
import { HttpStatusCode } from 'axios';
import EmbedLogin from 'lib/AlltIdAuth/index';
import { getLabradorConfig } from 'lib/configs/labrador';
import { getLabradorPage } from 'lib/labrador/getLabradorPage';
import { getLabradorPageProps, LabradorPageProps } from 'lib/labrador/getLabradorPageProps';
import { handleCommonLabradorPageStatus } from 'lib/labrador/handleCommonLabradorPageStatus';
import { isGoneEtikettPage } from 'lib/labrador/isGoneEtikettPage';
import { renderPostTypeComponent } from 'modules/dynamic/components';
import { GetServerSideProps, NextPage } from 'next';

const Page: NextPage<LabradorPageProps> = ({ pageData, adsData, analyticsData }) => {
  return (
    <Base
      content={
        <>
          <Consent />
          <PageMeta pageData={pageData} />
          <AdTemplate pageMeta={pageData?.meta as Meta} adsData={adsData} />
          <Tracking {...analyticsData} />
          <SchemaMarkup pageData={pageData} />
          <EmbedLogin />
          {renderPostTypeComponent(pageData)}
          <Pagination
            pages={pageData.meta.pagesLength as number}
            page={pageData.meta.pageNumber as number}
            withEdgePages
          />
          <div id="modal" />
        </>
      }
    />
  );
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { device, tag, params = [] } = context.params as { device: string; tag: string; params?: string[] };

  const isMainTag = (await getLabradorConfig('tags.section'))?.includes(tag);

  if (isMainTag) {
    return { redirect: { destination: `/${slugify(tag)}`, permanent: true } };
  }

  const pageNumberParam = params[0];

  if (Number(pageNumberParam) === 1) {
    return { redirect: { destination: `/etikett/${encodeURIComponent(tag)}`, permanent: true } };
  }

  const isPageNumberInvalid = pageNumberParam && (!Number(pageNumberParam) || Number(pageNumberParam) < 1);
  const hasMoreParams = params.length > 1;

  if (isPageNumberInvalid || hasMoreParams || isDeviceInvalid(device)) {
    return { notFound: true };
  }

  if (await isGoneEtikettPage(context.req.url)) {
    context.res.statusCode = HttpStatusCode.Gone;
    return { props: { isGone: true } };
  }

  const routeKey = ['etikett', tag, ...params];
  const data = await getLabradorPage(device, routeKey, context);

  const commonLabradorResponse = handleCommonLabradorPageStatus(data);
  if (commonLabradorResponse) return commonLabradorResponse;

  withOcelotCacheTagsHeaders(context, data);
  withLockedContentHeaders(context, data);
  withTestContentHeaders(context, data);
  withCacheControlHeaders(context.res, CLOUDFLARE_TTL);

  return withSerializationGuard({
    props: getLabradorPageProps(data),
  });
};

export default Page;
